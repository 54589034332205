import React from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useSwiper } from 'swiper/react';

export const SliderButton = () => {
  const swiper = useSwiper();

  return (
    <div className="swiper-nav-btns flex gap-5 text-white font-bold">
      <div>
      <button onClick={() => swiper.slidePrev()} className='px-5 py-3 shadow-md rounded-md bg-[#CA4757] text-white text-lg font-semibold'><AiOutlineArrowLeft/></button>
      </div>
      <button onClick={() => swiper.slideNext()} className='px-5 py-3 shadow-md rounded-md bg-[#CA4757] text-white text-lg font-semibold'><AiOutlineArrowRight/></button>
    </div>
  );
};