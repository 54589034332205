import React, { useContext } from 'react';

import { toast } from 'react-hot-toast';
import { AiFillInstagram } from 'react-icons/ai';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthProvider';
import useAdmin from '../../Hooks/UseAdmin';
import Loading from '../../Shared/Loading';


const Team = () => {

    const { user } = useContext(AuthContext)
    const [isAdmin] = useAdmin(user?.email);

    const url = "https://brooklyn-server-v4.vercel.app/employees";

    const { data: employees = [], isLoading, refetch } = useQuery({
        queryKey: ['users',],
        queryFn: async () => {
            const res = await fetch(url);
            const data = await res.json();
            return data;
        }

    })

    const deleteHandler = (employer) => {

        const proceed = window.confirm(
            `Are you sure, you want to delete ${employer?.name}?`
        );
        if (proceed) {
            fetch(`https://brooklyn-server-v4.vercel.app/employees/${employer?._id}`, {
                method: 'DELETE',

            })
                .then(res => res.json())
                .then(data => {
                    if (data.deletedCount) {
                        toast.success(`${employer?.name} Delete SuccessFully`)
                        refetch();

                    }


                })

        }

    }
    return (
        <div className='min-h-screen'>
            <section className="bg-white ">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
                    <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
                        <h2 className="mb-4 text-4xl playrify tracking-tight font-semibold text-gray-900 ">Meet Our Team</h2>
                        <p className="font-light text-gray-500 lg:mb-16 sm:text-xl ">The Talented People Behind the Scenes of the Organization</p>
                    </div>

                    {
                        isLoading &&
                        <Loading></Loading>
                    }


                    <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-1 lg:grid-cols-2 ">

                        {
                            employees?.map(employee => <div

                                key={employee?._id}

                                className="items-center bg-gray-50 rounded-lg shadow-lg sm:flex  " data-aos="zoom-in" data-aos-duration="1000" >

                                <img className="w-full  md:h-[220px] md:w-[220px] rounded-lg sm:rounded-none sm:rounded-l-lg" src={employee?.img} alt="Navin Shivpal" />

                                <div className="p-5">
                                    <h3 className="text-xl font-bold tracking-tight text-gray-900 ">
                                        <a href="#">{employee?.name}</a>
                                    </h3>
                                    <span className="text-gray-500 ">{employee?.designation} </span>
                                    <p className="mt-3 mb-4 font-light text-gray-500 ">
                                        {employee?.description}
                                    </p>
                                    <div className='flex justify-between'>
                                        <ul className="flex space-x-4 sm:mt-0 items-center">
                                            {
                                                employee?.facebook == null || employee?.facebook === "" ?
                                                    <>
                                                        <li>
                                                            <a href="#" className="text-gray-500 hover:text-gray-900 ">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
                                                            </a>
                                                        </li>
                                                    </>
                                                    :
                                                    <>
                                                        <li>
                                                            <a href={employee?.facebook}
                                                                target='_blank'
                                                                className="text-gray-500 hover:text-gray-900 " rel="noreferrer">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
                                                            </a>
                                                        </li>

                                                    </>
                                            }

                                            {
                                                employee?.twitter == null || employee?.twitter === "" ?
                                                    <>
                                                        <li>
                                                            <a href="#" className="text-gray-500 hover:text-gray-900">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                                                            </a>
                                                        </li>
                                                    </>
                                                    :
                                                    <>
                                                        <li>
                                                            <a href={employee?.twitter}

                                                                target='_blank'
                                                                className="text-gray-500 hover:text-gray-900 " rel="noreferrer">
                                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                                                            </a>
                                                        </li>
                                                    </>
                                            }

                                            {
                                                employee?.instagram == null || employee?.instagram === "" ?
                                                    <>
                                                        <li>
                                                            <a href="#">
                                                                <AiFillInstagram className='text-gray-500 text-xl hover:text-black' />
                                                            </a>
                                                        </li>
                                                    </>
                                                    :
                                                    <>
                                                        <li>
                                                            <a href={employee?.instagram}

                                                                target='_blank' rel="noreferrer"
                                                            >
                                                                <AiFillInstagram className='text-gray-500 text-xl hover:text-black' />
                                                            </a>
                                                        </li>
                                                    </>
                                            }


                                        </ul>
                                        {
                                            isAdmin &&
                                            <>
                                                <div>
                                                    <Link

                                                        to={`/employees/${employee?._id}`}

                                                    >
                                                        <button className='btn btn-sm'>
                                                            Update
                                                        </button>
                                                    </Link>

                                                    <button
                                                        onClick={() => deleteHandler(employee)}
                                                        className='btn btn-sm ml-3'>
                                                        Delete
                                                    </button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>)
                        }






                    </div>
                </div>
            </section>
        </div>
    );
};

export default Team;