import React from "react";
import ClassSlider from "../ClassSlider/ClassSlider";
import CoursesCard from "../Home/CoursesCard";
import Upcomming from "../ClassNotice/Upcomming";
import Chat from "../../Shared/Chat/Chat";
import ViewPdf from "../DashBoard/PdfUploader/ViewPdf";
import PageComponent from "../../PageComponent/PageComponent";


const Classes = () => {

  return (
    <div className="dark:bg-slate-600">
      
      <PageComponent
        title="Classes - Brooklyn Institute Of Vocational Training"
        description="Explore the complete class schedules and notices at Brooklyn Institute Of Vocational Training. Find detailed information about our diverse offerings, from vocational training to specialized courses. Stay updated with the latest scheduling changes and important class announcements, ensuring you never miss an opportunity to learn and grow."
        keywords="Brooklyn Institute class schedule, vocational training courses, educational programs, class notices, Brooklyn vocational training, BI vocational courses, class updates Brooklyn, professional training schedules, educational notices, Brooklyn Institute training"
      />

      <Upcomming></Upcomming>
      <CoursesCard></CoursesCard>
      <ViewPdf></ViewPdf>

      <div className="bg-[#E8F3F8] dark:bg-slate-600">
        <div className="w-[95%] md:w-[90%] lg:w-[75%] mx-auto pb-10 md:pb-20 pt-10">
          <h1 className="text-center text-xl md:text-3xl font-semibold playrify dark:text-gray-100 ">
            {" "}
            Become A Certified HHA Today
          </h1>
          <div className="flex justify-center mt-2">
            <hr className="w-[70px] border-t-[2px] border-gray-500 mb-5 md:pb-10 dark:border-gray-100" />
          </div>
          <ClassSlider></ClassSlider>
        </div>
      </div>
      <Chat></Chat>
    </div>
  );
};

export default Classes;
