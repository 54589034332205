import { Link } from "react-router-dom";
import React, { useContext, useState } from "react";
import "./Graduation.css";
import Chat from "../../Shared/Chat/Chat";
import VideoGallery from "./VideoGallery";

import img1 from "../../../src/Assets/graduation/6.jpg";
import img2 from "../../../src/Assets/weekend-graduation/img-11.jpg";
import img3 from "../../../src/Assets/MorningGraduation/img6.jpg";
import img4 from "../../../src/Assets/EveningGraduation/img1.jpg";
import img5 from "../../../src/Assets/New-Weekend/img6.jpg";
import img6 from "../../../src/Assets/NewEve/img2.jpg";
import img7 from "../../../src/Assets/Morning-12-08-2023/img10.jpg";
import img8 from "../../../src/Assets/Evening-12-15-2023/cover.jpg";
import img9 from "../../../src/Assets/weekend-12-24-2023/weekendclass12-2-2023.webp";
import img10 from "../../../src/Assets/Morning-12-29-2023/card-cover.jpg";
import img11 from "../../../src/Assets/Morning-01-19-2024/CARD.jpg";
import img12 from "../../../src/Assets/Evening-02-09-2024/cover.webp";
import img13 from "../../../src/Assets/Weekend-02-11-2024/weekend-cover.webp";
import img14 from "../../../src/Assets/morning-03-01-2024/cover.webp";
import img15 from "../../../src/Assets/morning-03-22-2024/card-cover.webp";
import img16 from "../../../src/Assets/Weekend-03-24-2024/card-cover.jpg";
import img17 from "../../../src/Assets/Evening-04-05-20024/cover-card.jpg";
import img18 from "../../../src/Assets/Morning-04-12-2024/img19.jpg";
import img19 from "../../../src/Assets/morning-05-03-2024/card-cover.jpg";
import img20 from "../../../src/Assets/Weekend-05-05-20024/card-cover.jpg";
import img21 from "../../../src/Assets/Evening-05-03-2024/card-cover.jpg";
import img22 from "../../../src/Assets/Morning-05-24-2024/card-cover.webp";
import img23 from "../../../src/Assets/Morning-06-14-2024/img1.jpg";
import img24 from "../../../src/Assets/Evening-05-31-2024/card-cover.jpg";
import img25 from "../../../src/Assets/Evening-07-26-2024/card-cover.jpg";
import img26 from "../../../src/Assets/Morning-07-26-2024/card.jpg";
import img27 from "../../../src/Assets/Weekend-07-28-2024/card.jpg";
import img28 from "../../../src/Assets/Evening-6-28-2024/card.jpg";
import img29 from "../../../src/Assets/Morning-08-16-24/card-update.jpg";
import img30 from "../../../src/Assets/Morning-07-05-2024/card-cover.jpg";

import PageComponent from "../../PageComponent/PageComponent";
import { useQuery } from "@tanstack/react-query";
import useAdmin from "../../Hooks/UseAdmin";
import { AuthContext } from "../../Context/AuthProvider";
import toast from "react-hot-toast";
import Loading from "../../Shared/Loading";

const ITEMS_PER_PAGE = 9; // You can adjust this number as needed

const allGraduations = [
  {
    img: img29,
    title: "Morning Class - 08/16/2024",
    path: "graduation/Morning-08-16-2024",
  },
  {
    img: img27,
    title: "Weekend Class - 07/28/2024",
    path: "graduation/Weekend-07-28-2024",
  },
  {
    img: img26,
    title: "Morning Class - 07/26/2024",
    path: "graduation/Morning-07-26-2024",
  },
  {
    img: img25,
    title: "Evening Class - 07/26/2024",
    path: "graduation/Evening-07-26-2024",
  },
  {
    img: img30,
    title: "Morning Class - 07/05/2024",
    path: "graduation/morning-07-05-2024",
  },
  {
    img: img28,
    title: "Evening Class - 06/28/2024",
    path: "graduation/Evening-06-28-2024",
  },

  {
    img: img23,
    title: "Morning Class - 06/14/2024",
    path: "graduation/Morning-06-14-2024",
  },
  {
    img: img24,
    title: "Evening Class - 05/31/2024",
    path: "graduation/Evening-05-31-2024",
  },
  {
    img: img22,
    title: "Morning Class - 05/24/2024",
    path: "graduation/Morning-05-24-2024",
  },
  {
    img: img20,
    title: "Weekend Class - 05/05/2024",
    path: "graduation/Weekend-05-05-2024",
  },
  {
    img: img21,
    title: "Evening Class - 05/03/2024",
    path: "graduation/evening-05-03-2024",
  },
  {
    img: img19,
    title: "Morning Class - 05/03/2024",
    path: "graduation/morning-05-03-2024",
  },
  {
    img: img18,
    title: "Morning Class - 04/12/2024",
    path: "graduation/morning-04-12-2024",
  },
  {
    img: img17,
    title: "Evening Class - 04/05/2024",
    path: "graduation/evening-04-05-2024",
  },
  {
    img: img16,
    title: "Weekend Class - 03/24/2024",
    path: "graduation/weekEnd-03-24-2024",
  },
  {
    img: img15,
    title: "Morning Class - 03/22/2024",
    path: "graduation/morning-03-22-2024",
  },
  {
    img: img14,
    title: "Morning Class - 03/01/2024",
    path: "graduation/morning-03-01-2024",
  },
  {
    img: img13,
    title: "Weekend Class - 02/11/2024",
    path: "graduation/weekend-02-11-2024",
  },
  {
    img: img12,
    title: "Evening Class - 02/09/2024",
    path: "graduation/evening-02-09-2024",
  },
  {
    img: img11,
    title: "Morning Class - 01/19/2024",
    path: "graduation/morning-1-19-2024",
  },
  {
    img: img10,
    title: "Morning Class - 12/29/2023",
    path: "graduation/morning-12-29-2023",
  },
  {
    img: img9,
    title: "Weekend Class - 12/24/2023",
    path: "graduation/weekend-12-24-2023",
  },
  {
    img: img8,
    title: "Evening Class - 12/15/2023",
    path: "graduation/evening-12-15-2023",
  },
  {
    img: img7,
    title: "Morning Class - 12/08/2023",
    path: "graduation/morning-12-08-2023",
  },
  {
    img: img6,
    title: "Evening Class - 11/17/2023",
    path: "graduation/evening-11-17-2023",
  },
  {
    img: img5,
    title: "Weekend Class - 11/12/2023",
    path: "graduation/weekend-11-12-2023",
  },
  {
    img: img4,
    title: "Evening Class - 10/20/2023",
    path: "graduation/evening-10-20-2023",
  },
  {
    img: img3,
    title: "Morning Class - 10/20/2023",
    path: "graduation/morning-10-20-2023",
  },
  {
    img: img2,
    title: "Weekend Class - 2023",
    path: "graduation/weekend-2023",
  },

  {
    img: img1,
    title: "Graduation Picture - 2023",
    path: "graduation/graduation-2023",
  },
];

const Graduation = () => {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [itemsPerPage] = useState(ITEMS_PER_PAGE); // Items per page
  const [paginationLoading, setPaginationLoading] = useState(false); // State for loading during pagination

  const url = `https://brooklyn-server-v4.vercel.app/albums`;

  const {
    data: newAlbum = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["albums"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });
  // Convert the newAlbum data to the structure of allGraduations

  let newAlbumEntry = [];

  if (newAlbum?.length > 0) {
    newAlbumEntry = newAlbum?.map((album) => ({
      img: album?.cardCover,
      title: album?.albumName,
      path: album?.albumName,
      id: album?._id,
      fromDatabase: true,
    }));
  }

  // Insert the new album data at the beginning of allGraduations
  const updatedAllGraduations = [...newAlbumEntry, ...allGraduations];

  // console.log(updatedAllGraduations);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = updatedAllGraduations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(updatedAllGraduations.length / itemsPerPage);

  const paginate = (pageNumber) => {
    // Set pagination loading to true
    setPaginationLoading(true);

    // Simulate loading with a timeout or fetch
    setTimeout(() => {
      setCurrentPage(pageNumber);
      setPaginationLoading(false); // Turn off loading after data is ready
    }, 1000); // Adjust timeout for realistic loading effect
  };

  const deleteHandler = (grad) => {
    // console.log(setNewClass)
    const proceed = window.confirm(
      `Are you sure, you want to delete "${grad?.title}" ?`
    );
    if (proceed && grad?.id) {
      fetch(`https://brooklyn-server-v4.vercel.app/albums/${grad?.id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message) {
            toast.success("Album deleted SuccessFully");
            refetch();
          } else {
            toast.error("Failed to delete Album");
          }
        });
    } else {
      toast.error("This is a static album. You can't edit or update it.");
    }
  };

  return (
    <div className="dark:bg-slate-600">
      <PageComponent
        title="Graduation - Brooklyn Institute Of Vocational Training"
        description="Celebrate the achievements of our graduates at the Brooklyn Institute Of Vocational Training. Browse through our gallery of graduation class photos, capturing the joy and pride of our students and their families. Each picture tells a story of hard work and success, marking the culmination of our students' educational journeys. Join us in honoring their accomplishments."
        keywords="Brooklyn Institute graduation photos, BI vocational training graduation, graduation ceremony pictures, graduate success stories, educational achievement Brooklyn, vocational training graduation, Brooklyn Institute graduates, BI class graduation gallery, student graduation photos, Brooklyn vocational ceremony"
      />

      <div className="graduation-banner min-h-[80vh] relative">
        <div
          className=" max-w-3xl  mx-auto graduation-description absolute rounded-md top-[38vh] 
                md:top-[400px] md:left-[10%] p-8"
          id="graduation-text"
        >
          <h1 className="text-white md:text-5xl text-xl font-semibold">
            Graduation
          </h1>
          <div className="md:mt-4 mt-3 w-[80%]">
            <hr className="border-t-white border-t-[2px] " />
            <p className="text-white md:my-3 font-semibold md:text-2xl my-4 medium-device-font">
              Congratulations to all graduates from the class of 2023 to 2024 -
              we enjoyed celebrating with you.
            </p>
            <p className="text-white md:my-3 font-medium md:text-xl my-4 medium-device-font">
              If you have any queries related to your Graduation, please contact
              us.
            </p>
            <Link to="/contact">
              <button className="bg-[#E9454B] px-4 py-2 rounded-md text-white md:text-xl font-medium">
                Contact us
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* graduation picture start  */}

      <h1 className="text-xl md:text-3xl font-semibold text-center pt-8 dark:text-gray-100">
        All Graduation Pictures
      </h1>

      <div className="flex justify-center mt-1">
        <hr className="w-14 border-t-[2px] border-[#CA4757] " />
      </div>

      {isLoading || paginationLoading ? (
      
          <Loading />
       
      ) : (
        <div className="lg:w-[70%] mx-auto mt-8 w-[95%] " id="graduation-card">
          <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 md:gap-10 gap-7 ">
            {currentItems?.map((grad, index) => (
              <div key={index || grad?._id}>
                <Link
                  className="relative overflow-hidden h-64 md:h-48 xl:h-64 block "
                  to={
                    grad?.fromDatabase
                      ? `/graduation/${grad?.title}`
                      : `/${grad?.path}`
                  }
                >
                  <img
                    src={grad?.img}
                    alt={grad?.img}
                    className="w-full h-fit object-cover  transition duration-500 transform hover:scale-105 "
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-black via-black to-black opacity-0 hover:opacity-25 transition duration-300 flex items-center justify-center">
                    <p className="text-white text-center text-lg font-semibold">
                      View Your Gallery
                    </p>
                  </div>
                </Link>
                <h3 className="uppercase text-center mt-1 font-medium tracking-widest dark:text-gray-100 text-gray-600 text-lg">
                  {grad?.title &&
                    grad.title.replace(
                      /(\d{2})-(\d{2})-(\d{4})/,
                      (match, p1, p2, p3) => `${p1}/${p2}/${p3}`
                    )}
                </h3>

                {user?.uid && isAdmin && (
                  <div className="flex gap-5 mt-3 justify-center">
                    <Link
                      to={grad?.fromDatabase ? `/update/${grad?.title}` : "#"} // '#' to prevent navigation if it's static
                      className="px-2 py-1 rounded-md bg-blue-600 text-white"
                      onClick={(e) => {
                        if (!grad?.fromDatabase) {
                          e.preventDefault(); // Prevents the link from navigating
                          toast.error(
                            "This is a static Album, you can't edit or delete it."
                          );
                        }
                      }}
                    >
                      Update
                    </Link>

                    <button
                      onClick={() => deleteHandler(grad)}
                      className="px-2 py-1 rounded-md bg-primary text-white"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* pagination start  */}

          <div className="flex justify-center mt-8">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-3 py-1 ${
                currentPage === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
              } rounded-md mx-1`}
            >
              Previous
            </button>
            {[...Array(totalPages).keys()].map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => paginate(pageNumber + 1)}
                className={`px-3 py-1 ${
                  currentPage === pageNumber + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300"
                } rounded-md mx-1`}
              >
                {pageNumber + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`px-3 py-1 ${
                currentPage === totalPages
                  ? "bg-gray-300"
                  : "bg-blue-500 text-white"
              } rounded-md mx-1`}
            >
              Next
            </button>
          </div>

          {/* paginaton end  */}
        </div>
      )}

      {/* video gallery start  */}

      <VideoGallery />

      {/* video gallery end */}

      <Chat></Chat>
    </div>
  );
};

export default Graduation;
