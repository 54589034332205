import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { BiUserCircle } from "react-icons/bi";
import { IoSendSharp } from 'react-icons/io5';
import { useQuery } from '@tanstack/react-query';
import { FaRegUserCircle } from "react-icons/fa";
import { IoReorderThree } from "react-icons/io5";
import Linkify from 'react-linkify';
import { IoMdArrowRoundUp } from "react-icons/io";


// import './conversation.css';

import axios from 'axios';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Shared/Loading';
import UpdateForm from '../UpdateForm/UpdateForm';



const Conversation = () => {

    const navigate = useNavigate()
    const chatDivRef = useRef();
    const email = localStorage.getItem('email')
    const [isOpen, setIsOpen] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const date = Date.now();

    const updateTime = (lastMessage, email,name) => {

        const info = {
            date,
            email: email,
            message: lastMessage,
            chat: 'active',
            name:name,

        }

        fetch(`https://brooklyn-server-v4.vercel.app/users/time`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(info),
        })
            .then((res) => res.json())
            .then((data) => {
                //    console.log(data)
                if (data.acknowledged) {
                    toast.success('message send successfully')
                }
            });
    }

    const clearSession = () => {

        const proceed = window.confirm(
            `Are you sure, you want to log out ? `
        );
        if (proceed) {

            localStorage.removeItem('email')
            navigate("/")

        }

    }
    const { isLoading, data, refetch } = useQuery({
        queryKey: ["chats"],  // Changed to an array
        queryFn: async () => {
            const { data } = await axios.get(
                `https://brooklyn-server-v4.vercel.app/chats/allChats?email=${email}`
            );
            return data;
        },
        refetchInterval: 1000,
    });

    // console.log(data)
    
    useEffect(() => {
        // Use setTimeout to scroll after a short delay (you can adjust the delay)
        setTimeout(() => {
            chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
        }, 100); // Adjust the delay as needed
    }, [data]);

    // console.log(data)

    let messageLength = data?.length;

    if (messageLength && localStorage.getItem('chatLength')) {

        if (messageLength > localStorage.getItem('chatLength')) {

            localStorage.setItem('chatLength', messageLength)

        }
    }


    const submitHandler = (allData) => {
        const lastMessage = allData?.message
        const name = localStorage.getItem('name')
        const email = localStorage.getItem('email')

        let photoURL = '';
        if(data?.[0]?.photoURL){
            photoURL = data?.[0]?.photoURL
        }

        if (localStorage.getItem("email")) {

            const message = {
                firstName: name,
                email: email,
                time: date,
                messages: allData?.message,
                photoURL
            };
            fetch(
                "https://brooklyn-server-v4.vercel.app/chats",
                {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify(message),
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    //    console.log(data)
                    if (data.acknowledged) {

                        updateTime(lastMessage, email,name)

                        if (messageLength) {
                            localStorage.setItem('chatLength', messageLength + 1)
                        }

                        reset();
                        refetch()

                    }

                });
        };


    }


    const customComponentDecorator = (href, text, key) => (
        <a href={href} key={key} style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      );

    return (

        <div className='dark:bg-slate-600 bg-slate-200'>
            <div className='pb-10 w-full md:w-[80%] mx-auto dark:bg-slate-800 bg-slate-300 shadow-md'>
                {
                    data?.length &&

                    <div className=' flex items-center gap-4 sticky top-0 bg-slate-600 dark:bg-neutral-700 px-2 md:px-4 py-1 mb-2'>
                        {
                            data?.[0]?.photoURL &&
                            <img src={data?.[0]?.photoURL} alt={data?.[0]?.firstName} className='w-12 h-12 rounded-full' />
                        }

                        {
                            !data?.[0]?.photoURL &&
                            <FaRegUserCircle className='w-12 h-12 text-white dark:text-gray-100'></FaRegUserCircle>
                        }
                        <p className='md:text-2xl font-semibold text-sm text-gray-100'>

                            {data?.[0]?.firstName}

                        </p>




                        <div className='md:ml-10 ml-3 text-gray-100 cursor-pointer
                        text-3xl'

                            onClick={clearSession}
                        >
                            <div className='flex items-center gap-2'>
                                <p className='md:text-xl text-sm font-bold'>
                                    Log Out
                                </p>
                                <FiLogOut className='md:text-xl text-base'></FiLogOut>
                            </div>

                        </div>

                        <div className='cursor-pointer flex justify-end  md:w-40  ml-auto mr-10 lg:mr-0  '

                        >

                            <div>

                                <div className="relative inline-block text-left">
                                    <button
                                        className=""
                                        onClick={() => setIsOpen(!isOpen)} // Toggle the dropdown state on button click
                                    >
                                        <IoReorderThree className='text-gray-100 md:text-3xl text-3xl' />
                                    </button>
                                    {isOpen && (
                                       <UpdateForm
                                       id={data?.[0]?._id}
                                       userInfo={data?.[0]}
                                       
                                       /> 
                                    )}
                                </div>
                            </div>

                        </div>


                    </div>





                }



                <div className='px-6 pb-6 big-body h-[55vh] overflow-y-scroll ' ref={chatDivRef}>



                    {
                        isLoading &&
                        <div className='mt-5'>
                            <Loading />
                        </div>
                    }



                    {
                        data?.map(message => <div

                            key={message?._id}
                            className='my-2'

                        >
                            {
                                !message?.role &&


                                <p className='flex items-center gap-3 '>

                                    {
                                        data?.[0].photoURL &&
                                        <img src={data?.[0].photoURL} alt="" className='w-6 h-6 rounded-full' />

                                    }
                                    {
                                        !data?.[0].photoURL &&

                                        <BiUserCircle className='w-6 h-6 rounded-full dark:text-gray-100'></BiUserCircle>

                                    }

                                    <span className='bg-gray-400 text-white px-3 py-1.5 rounded-md shadow-md  ' style={{ whiteSpace: "pre-line" }}>  <Linkify componentDecorator={customComponentDecorator}>
                                    {message?.messages}
                                    </Linkify> </span>
                                </p>
                            }
                            {
                                message?.role &&
                                <p className='flex items-center gap-3 justify-end '>
                                    <span className='bg-primary text-white px-3 py-1.5 rounded-md shadow-md ' style={{ whiteSpace: "pre-line" }}> <Linkify componentDecorator={customComponentDecorator}>
                                    {message?.messages}
                                    </Linkify> </span>
                                    <img src={message?.photoURL} alt="" className='w-6 h-6 rounded-full' />

                                </p>
                            }



                        </div>)
                    }

                </div>

                <div className="lg:mt-5 -mt-6 ">
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className=" lg:w-[90%] w-[92%] mx-auto relative ">
              <textarea
                type="text"
                {...register("message", {
                  required: "message is required",
                })}
                // name="subject"
                id="message"
                rows={2}
                placeholder="Type Your Message"
                className="w-full px-4 py-3 border rounded-md text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                              focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevent the default action to avoid new line
                    handleSubmit(submitHandler)(); // Manually trigger form submit
                  }
                }}
              />

              <button type="submit">
                <IoMdArrowRoundUp className=" bg-black p-1 absolute right-4 bottom-6 b  text-white text-4xl rounded-md"></IoMdArrowRoundUp>
              </button>
            </div>
            {errors.message && (
              <p className="text-red-600 lg:w-[90%] w-[92%] mx-auto ">
                {errors.message.message}
              </p>
            )}
          </form>
        </div>

            </div>

        </div>
    );
};

export default Conversation;