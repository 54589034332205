import React, { useContext, useState } from "react";
import { BsChatLeft } from "react-icons/bs";
import axios from "axios";
import { useForm } from "react-hook-form";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AuthContext } from "../../Context/AuthProvider";
import NewLoading from "../NewLoading";




const Chat = () => {

  const {user} = useContext(AuthContext)
  // console.log(user)
  const [loading,setLoading] = useState(false)

  const [chat, setChat] = useState("");
    const navigate = useNavigate();  

    const date = Date.now();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();




  // Your other code...


  const email = localStorage.getItem("email")
  const chatLength  = localStorage.getItem("chatLength")



  const { isLoading, data, refetch } = useQuery({
    queryKey: ["allChat"], // Changed to an array
    queryFn: async () => {
      const { data } = await axios.get(
        `https://brooklyn-server-v4.vercel.app/chats/allChats?email=${email}`
      );
      return data;
    },
    refetchInterval: 1500,
  });

//   console.log(data)

  const emailStorage = (email,name) => {
    localStorage.setItem("chatLength", 1);
    localStorage.setItem("email", `${email}`);
    localStorage.setItem("name", `${name}`);
  }
 

  const saveUser = (name, email, verify = "false",chat='', time= date) => {
    const newUser = { name, email, verify,chat, time};
    fetch('https://brooklyn-server-v4.vercel.app/users', {
      method: 'PUT',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(newUser)
    })
      .then(res => res.json())
      .then(data => {
        //    console.log(data)
        if (data.acknowledged) {

            emailStorage(email, name)
            reset()
            setLoading(false)
            toast.success("Welcome To Brooklyn Chat Boat")
            navigate('/conversation')
        }

      })
  }


  const chatHandler = () => {

    if(localStorage.getItem('email')){

        navigate('/conversation')
    }

    else{
        setChat(true);
    }

    

    
    
  };


  const startChat = (data)=>{
    
    setLoading(true)

    const name = data?.name;
    const email = data?.email;

    const message ={

        firstName : data?.name,
        email:data?.email
    }

    fetch(
        "https://brooklyn-server-v4.vercel.app/chats/modifyChat",
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(message),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.acknowledged) {
            // toast.success("Message Send Successfully");
  
            saveUser(name, email)
  
          }
          // console.log(data)
        });

  }



  return (
    <div>
      {
        chat && !user &&
        
         (
          <div className="w-80 h-[300px] fixed right-10  google-form top-auto bottom-0 mb-[90px] md:mb-[105px] bg-slate-300 p-4 rounded-md shadow-md dark:bg-slate-600 z-50 ">
            <form onSubmit={handleSubmit(startChat)} className="space-y-5  p-4">
              <div className="space-y-6 ">
              <div className="flex justify-end -mt-2 mb-2 mr-4">
              <button
                onClick={() => setChat(false)}
                className="btn btn-circle btn-sm mt-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              </div>

              <div >
                  <input
                    type="text"
                    {...register("name", { required: "name is required" })}
                    id="email"
                    placeholder="Your Name"
                    className="w-full px-8 py-1.5  rounded-md bg-white shadow-2xl placeholder:text-gray-500 focus:outline-none dark:bg-gray-400 dark:text-gray-100 dark:placeholder:text-gray-100"
                    defaultValue={""}
                  />
                  {errors.name && (
                    <p className="text-red-600 text-xs">
                      {errors.name.message}
                    </p>
                  )}
                </div>

                <div className=" pt-2">
                  <input
                    type="email"
                    {...register("email", { required: "email is required" })}
                    id="email"
                    placeholder="Your Email"
                    className="w-full px-8 py-1.5  rounded-md bg-white shadow-2xl placeholder:text-gray-500 focus:outline-none dark:bg-gray-400 dark:text-gray-100 dark:placeholder:text-gray-100"
                    defaultValue={""}
                  />
                  {errors.email && (
                    <p className="text-red-600 text-xs">
                      {errors.email.message}
                    </p>
                  )}
                </div>



                <div className="mb-2">
                  <button
                    className="w-full px-4 py-1.5 rounded-md bg-primary text-white   gap-3 text-center"
                  >
                    Start Conversation
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      {
        chat && user &&
        
         (
          <div className="w-80 h-[300px] fixed right-10  google-form top-auto bottom-0 mb-[90px] md:mb-[105px] bg-slate-300 p-4 rounded-md shadow-md dark:bg-slate-600 z-50">
            <form onSubmit={handleSubmit(startChat)} className="space-y-5">
              <div className="space-y-6 ">
              <div className="flex justify-end -mt-2 mb-2 mr-3">
              <button
                onClick={() => setChat(false)}
                className="btn btn-circle btn-sm "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              </div>

              <div >
                  <input
                    type="text"
                    {...register("name", { required: "name is required" })}
                    id="email"
                    placeholder="Your Name"
                    className="w-full px-8 py-1.5  rounded-md bg-white shadow-2xl placeholder:text-gray-500 focus:outline-none dark:bg-gray-400 dark:text-gray-100 dark:placeholder:text-gray-100"
                    readOnly
                    defaultValue={user?.displayName}
                  />
                  {errors.name && (
                    <p className="text-red-600 text-xs">
                      {errors.name.message}
                    </p>
                  )}
                </div>

                <div className=" pt-2">
                  <input
                    type="email"
                    {...register("email", { required: "email is required" })}
                    id="email"
                    placeholder="Your Email"
                    className="w-full px-8 py-1.5  rounded-md bg-white shadow-2xl placeholder:text-gray-500 focus:outline-none dark:bg-gray-400 dark:text-gray-100 dark:placeholder:text-gray-100"
                    readOnly
                    defaultValue={user?.email}
                  />
                  {errors.email && (
                    <p className="text-red-600 text-xs">
                      {errors.email.message}
                    </p>
                  )}
                </div>



                <div className="mb-2">
                  <button
                    className="w-full px-4 py-1.5 rounded-md bg-primary text-white   gap-3 text-center"
                  >
                    Start Conversation
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}

      <div
        onClick={chatHandler}
        className={`md:w-16 md:h-16 w-11 h-11 z-50 rounded-full shadow-xl bg-primary fixed big-display  bottom-0 top-auto mb-[22px] md:mb-[40px] right-3 md:right-6 cursor-pointer`}
      >
        {  data?.length > chatLength && (
          <p className="flex items-center gap-2 text-xs font-semibold text-primary -mt-6 md:-mt-9 bg-white px-3 py-1 md:py-2 rounded-md w-28 fixed right-10 md:text-sm md:right-16 shadow-2xl">
            New {data?.length - chatLength }
            <BiMessageRoundedDetail className="font-bold text-lg"></BiMessageRoundedDetail>
          </p>
        )}


        {/* <p className="flex items-center gap-2 text-sm font-semibold text-gray-400  -mt-20   fixed right-16 shadow-2xl ">
         ""
        </p> */}


         
            {
              loading === true ?
              <>
              <p
              htmlFor="my-drawer"
              className="text-white flex justify-center text-center  mt-1.5 md:mt-0 md:p-2  text-2xl">
              <NewLoading/>
            </p>
              </>
              :
              <>
              <p
              htmlFor="my-drawer"
              className="text-white flex justify-center text-center mt-1.5 md:mt-2.5 p-2 text-xl md:text-3xl"
            >
              <BsChatLeft />
            </p>
              
              </>
            }
          
        
      </div>

    </div>
  );
};

export default Chat;
