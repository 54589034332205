import { createBrowserRouter } from "react-router-dom";
import DashBoardLayout from ".././Pages/DashBoard/DashBoardLayout/DashBoardLayout";
import Main from "../Main/Main";
import Classes from "../Pages/Classes/Classes";
import Contact from "../Pages/Contact/Contact";
import Conversation from "../Pages/Conversation/Conversation";
import GuestConversation from "../Pages/Conversation/GuestConversation";
import Users from "../Pages/DashBoard/Users";
import EmployeeForm from "../Pages/EmployeeForm";
import ErrorPage from "../Pages/ErrorPage";
import Home from "../Pages/Home/Home";
import Login from "../Pages/Login/Login";
import Register from "../Pages/Register/Register";
import Team from "../Pages/Team/Team";
import Chat from "../Shared/Chat/Chat";
import AdminRoute from "./AdminRoute";
import PrivateRoute from "./PrivateRoute";
import GraphPage from "../Pages/DashBoard/GraphPage/GraphPage";
import AllUsers from "../Shared/AllUsers/AllUsers";
import AllMail from "../Pages/DashBoard/AllMail/AllMail";
import ClassForm from "../Pages/DashBoard/ClassForm/ClassForm";
import ClassEditForm from "../Pages/DashBoard/ClassForm/ClassEditForm";
import UpdateCountDown from "../Pages/DashBoard/CountDown/UpdateCountDown";
import MobileConversation from "../Pages/DashBoard/MobileConversation/MobileConversation";
import PdfUploader from "../Pages/DashBoard/PdfUploader/PdfUploader";
import Graduation from "../Pages/Graduation/Graduation";
import AllGraduation from "../Pages/Graduation/AllGraduation";
import WeekEndGraduation from "../Pages/Graduation/WeekEndGraduation";
import MorningGraduation from "../Pages/Graduation/MorningGraduation";
import EveningGraduation from "../Pages/Graduation/EveningGraduation";
import NewWeekend from "../Pages/Graduation/NewWeekend";
import NewEve from "../Pages/Graduation/NewEve";
import MorningClass12082023 from "../Pages/Graduation/MorningClass12082023";
import EveningClass12152023 from "../Pages/Graduation/EveningClass12152023";
import WeekEnd12242023 from "../Pages/Graduation/WeekEnd12242023";
import Morning12292023 from "../Pages/Graduation/Morning12292023";
import Morning1192024 from "../Pages/Graduation/Morning1192024";
import Evening02092024 from "../Pages/Graduation/Evening02092024";
import Weekend02112024 from "../Pages/Graduation/Weekend02112024";
import Morning03012024 from "../Pages/Graduation/Morning03012024";
import Morning03222024 from './../Pages/Graduation/Morning03222024';
import WeekEnd03242024 from "../Pages/Graduation/WeekEnd03242024";
import Evening04052024 from "../Pages/Graduation/Evening04052024";
import Morning04122024 from "../Pages/Graduation/Morning04122024";
import Morning05032024 from "../Pages/Graduation/Morning05032024";
import Weekend05052024 from "../Pages/Graduation/Weekend05052024";
import Evening05032024 from "../Pages/Graduation/Evening05032024";
import ScrollToTop from "../Hooks/ScrollToTop";
import Morning05242024 from "../Pages/Graduation/Morning05242024";
import Morning06142024 from "../Pages/Graduation/Morning06142024";
import Evening05312024 from "../Pages/Graduation/Evening05312024";
import Evening07262024 from "../Pages/Graduation/Evening07262024";
import Morning07262024 from "../Pages/Graduation/Morning07262024";
import Weekend07282024 from "../Pages/Graduation/Weekend07282024";
import Evening06282024 from "../Pages/Graduation/Evening06282024";
import Morning08162024 from "../Pages/Graduation/Morning08162024";
import DragDrop from "../Pages/Graduation/DragDrop";
import DynamicAlbum from "../Pages/Graduation/DynamicAlbum";
import EditAlbum from "../Pages/Graduation/EditAlbum";
import Morning07052024 from "../Pages/Graduation/Morning07052024";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <Main />
      </>
    ),
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      
      {
        path: "/classes",
        element: <Classes></Classes>,
      },
      {
        path: "/contact",
        element: <Contact></Contact>,
      },
      {
        path: "/login",
        element: <Login></Login>,
      },
      {
        path: "/register",
        element: <Register></Register>,
      },
      {
        path: "/graduation/graduation-2023",
        element: <AllGraduation/>,
      },
      {
        path: "/graduation/weekend-2023",
        element: <WeekEndGraduation/>,
      },
      {
        path: "/graduation/Evening-07-26-2024",
        element: <Evening07262024/>
      },
      {
        path: "/graduation/Weekend-07-28-2024",
        element: <Weekend07282024/>
      },
      {
        path: "/graduation/Evening-06-28-2024",
        element: <Evening06282024/>
      },
      {
        path: "/graduation/Morning-07-26-2024",
        element: <Morning07262024/>
      },
      {
        path: "/graduation/Morning-07-26-2024",
        element: <Morning07262024/>
      },
      {
        path: "/graduation/Morning-08-16-2024",
        element: <Morning08162024/>
      },
     
      {
        path: "/graduation/morning-04-12-2024",
        element: <Morning04122024/>
      },
      {
        path: "/graduation/morning-05-03-2024",
        element: <Morning05032024/>
      },
      {
        path: "/graduation/weekend-02-11-2024",
        element: <Weekend02112024/>,
      },
      {
        path: "/graduation/morning-1-19-2024",
        element: <Morning1192024/>,
      },
      {
        path: "/graduation/morning-03-22-2024",
        element: <Morning03222024/>,
      },
      {
        path: "/graduation/morning-10-20-2023",
        element: <MorningGraduation/>,
      },
      {
        path: "/graduation/morning-05-24-2024",
        element:<Morning05242024/>,
      },
      {
        path: "/graduation/morning-06-14-2024",
        element:<Morning06142024/>,
      },
      {
        path: "/graduation/evening-10-20-2023",
        element: <EveningGraduation/>
      },
      {
        path: "/graduation/weekend-11-12-2023",
        element: <NewWeekend/>
      },
      {
        path: "/graduation/morning-07-05-2024",
        element: <Morning07052024/>
      },
      {
        path: "/graduation/weekend-05-05-2024",
        element: <Weekend05052024/>
      },
      {
        path: "/graduation/evening-11-17-2023",
        element:<NewEve/>
      },
      {
        path: "/graduation/evening-05-03-2024",
        element: <Evening05032024/>
      },
      {
        path: "/graduation/evening-05-31-2024",
        element: <Evening05312024/>
      },
      {
        path: "/graduation/evening-04-05-2024",
        element:<Evening04052024/>
      },
      {
        path: "/graduation/morning-12-08-2023",
        element:<MorningClass12082023/>
      },
      {
        path: "/graduation/evening-12-15-2023",
        element:<EveningClass12152023/>
      },
      {
        path: "/graduation/weekend-12-24-2023",
        element:<WeekEnd12242023/>
      },
      {
        path: "/graduation/weekEnd-03-24-2024",
        element:<WeekEnd03242024/>
      },
      {
        path: "/graduation/morning-12-29-2023",
        element:<Morning12292023/>
      },
      {
        path: "/graduation/evening-02-09-2024",
        element:<Evening02092024/>
      },
      {
          path:'/team',
          element:<Team></Team>
      },
      {
        path: "/chat",
        element: <Chat></Chat>,
      },
      {
        path: "/conversation",
        element: <Conversation></Conversation>,
      },

      {
        path: "/guest",
        element: <GuestConversation></GuestConversation>,
      },
      {
        path: "/graduation",
        element:<Graduation></Graduation>
       
      },
      {
        path: "/graduation/:title",
        loader: async ({ params }) => {
          const response = await fetch(`https://brooklyn-server-v4.vercel.app/albums/${params?.title}`);
          if (!response.ok) {
            throw new Response("Not Found", { status: 404 });
          }
          return response.json();
        },
        element: <DynamicAlbum/>
      },
      {
        path: "/update/:title",
        loader: async ({ params }) => {
          const response = await fetch(`https://brooklyn-server-v4.vercel.app/albums/${params?.title}`);
          if (!response.ok) {
            throw new Response("Not Found", { status: 404 });
          }
          return response.json();
        },
        element: <EditAlbum/>
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <DashBoardLayout>    
        </DashBoardLayout>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: "/dashboard",
        element: (
          <AdminRoute>
            <GraphPage></GraphPage>
          </AdminRoute>
        ),
      },

      {
        path: "/dashboard/allMessages",
        element: (
          <AdminRoute>
            <Users></Users>
          </AdminRoute>
        ),
      },

      {
        path: "/dashboard/allUsers",
        element: (
          <AdminRoute>
            <AllUsers></AllUsers>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/allMails",
        element: (
          <AdminRoute>
            <AllMail></AllMail>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/addEmployee",
        element: (
          <AdminRoute>
            <EmployeeForm />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/classForm",
        element: (
          <AdminRoute>
            <ClassForm></ClassForm>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/singleClass/:id",
        loader: ({ params }) =>
          fetch(
            `https://brooklyn-server-v4.vercel.app/classes/singleClass/${params.id}`
          ),
        element: <ClassEditForm></ClassEditForm>,
      },
      {
        path: "/dashboard/countdown/:id",
        loader: ({ params }) =>
          fetch(
            `https://brooklyn-server-v4.vercel.app/classes/countdown/${params.id}`
          ),
        element: <UpdateCountDown></UpdateCountDown>,
      },
        {
          path: '/dashboard/singleUser/:id',
          loader: ({ params }) => fetch(`https://brooklyn-server-v4.vercel.app/users/singleUser/${params.id}`),
          element: (
            <AdminRoute>
            <MobileConversation>
            </MobileConversation>
            </AdminRoute>
          ),
        },
        {
          path: '/dashboard/pdfUploader',
          element: (
            <AdminRoute>
              <PdfUploader></PdfUploader>
            </AdminRoute>
          ),
        },
        {
          path: '/dashboard/uploadAlbum',
          element: (
            <AdminRoute>
              <DragDrop/>
            </AdminRoute>
          ),
        },
      
    ],
  },
]);

export default router;
