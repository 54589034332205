import React, { useState, useEffect, useContext } from "react";
import CountUp from "react-countup";
import "./Odometer.css";
import { useQuery } from "@tanstack/react-query";

import './Odometer.css';
import { AuthContext } from "../../Context/AuthProvider";

const OdometerComponent = () => {
  const [inViewport, setInViewport] = useState(false);

  const { visitors } = useContext(AuthContext);

  // console.log(visitors)
  const previousVisitors = 225;

  let presentVisitors = 0;

  if (visitors) {
    for (const visitor of visitors) {
      presentVisitors = presentVisitors + visitor?.visitor;
    }
  }

  // console.log(presentVisitors)

  ///////

  const url = `https://brooklyn-server-v4.vercel.app/notifications`;

  const {
    data: allData = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  const checkViewport = () => {
    const element = document.getElementById("odometer-container");
    if (element) {
      const rect = element.getBoundingClientRect();
      const isElementInViewport =
        rect.top <= window.innerHeight && rect.bottom >= 0;
      setInViewport(isElementInViewport);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      checkViewport();
    };

    window.addEventListener("scroll", handleScroll);
    checkViewport();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      id="odometer-container"
      className="mt-10  odometer-bg bg-[#a8c3cf] flex justify-center items-center dark:bg-slate-500 md:h-[350px] h-full py-5 md:py-0"
    >
      {inViewport && (
        <div className="odometer w-[98%] lg:w-[90%] mx-auto md:justify-evenly  ">
          <div className="text-center leading-6  countdown-bg  rounded-lg md:rounded-full md:w-52 w-52 h-20 mt-2 md:mt-0 md:h-52 flex justify-center items-center">
            <div className='md:mt-4'>
              <p className="text-white text-xl  md:text-5xl font-bold ">
                <CountUp start={0} end={10000} duration={5} />+
              </p>
              <p className="text-white  text-md md:text-[28px] md:font-semibold mt-1 md:mt-3 ">
                Trusted <span className="md:hidden">People</span> <br />
                <span className="md:text-xl md:inline-block md:mt-1 hidden  "> People</span>
                
              </p>
            </div>
          </div>
          <div className="text-center leading-6  countdown-bg  rounded-lg md:rounded-full md:w-52 w-52 h-20 mt-2 md:mt-0 md:h-52 flex justify-center items-center">
            <div className='md:mt-4'>
              <p className="text-white text-xl  md:text-5xl font-bold ">
                <CountUp
                  start={0}
                  end={presentVisitors + previousVisitors}
                  duration={5}
                />+
              </p>
              <p className="text-white  text-md  md:text-[28px] md:font-semibold mt-1 md:mt-3">
                Total <span className="md:hidden">Visitors</span> <br />
                <span className="md:text-xl  md:inline-block md:mt-1 hidden "> Visitors</span>
                
              </p>
            </div>
          </div>
          <div className="text-center leading-6  countdown-bg  rounded-lg md:rounded-full w-52 h-20 mt-3 md:mt-0 md:w-52 md:h-52 flex justify-center items-center">
            <div className='md:mt-4'>
              <p className="text-white text-xl  md:text-5xl font-bold ">
                <CountUp start={0} end={3200} duration={5} />+
              </p>
              <p className="text-white  text-md md:text-[28px] md:font-semibold mt-1 md:mt-3">
               Graduated <span className="md:hidden">Students</span> <br /> <span className="md:text-xl  md:inline-block mt-1 hidden ">Students</span>
              </p>
            </div>
          </div>
          <div className="text-center leading-6  countdown-bg  rounded-lg md:rounded-full w-52 h-20 mt-3 md:mt-0 md:w-52 md:h-52 flex justify-center items-center">
            <div className='md:mt-4'>
              <p className="text-white text-xl md:text-5xl font-bold ">
                <CountUp start={0} end={5} duration={5} /> 
              </p>
              <p className="text-white  text-md md:text-[28px] md:font-semibold mt-1 md:mt-3 tracking-tight">
              Serving <span className="md:hidden">Boroughs</span> <br />
              <span className="md:text-xl md:inline-block md:mt-1  hidden ">Boroughs</span>
              
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OdometerComponent;
